import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Volunteer">
    <section className="section-volunteer">
      <strong>Your time is a valuable donation</strong>
      <p>We rely on our volunteers, and have opportunities available for groups and individuals year-round. Volunteers are absolutely vital to our work at Parakh</p>


      <br/>
      <iframe title="volunteers" src="https://docs.google.com/forms/d/e/1FAIpQLSfrNvy-fxCWgR4IQe99TWu1tjID6Ps7g0kcF3lvFN5ISBzBxg/viewform?embedded=true"
              width="100%" height="1000" frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe>


    </section>
  </Layout>
);

export default Page;

/*

  <!-- see http://www.northwestharvest.org/volunteer-->
  <!--
  Your time is a valuable donation
  We rely on our volunteers, and have opportunities available for groups and individuals year-round. Volunteers are absolutely vital to our work at Northwest Harvest.

  You can help by sorting and packing food at our warehouses in Kent, Yakima and Spokane; serving clients at Cherry Street Food Bank; or assisting staff at special events. Whether it’s once a week, once a month or once in a while, your time makes a great contribution.

  View Volunteer Insert (PDF)

  Opportunities
  Please view the list of opportunities below and complete a Northwest Harvest volunteer application including your preferred job or jobs. For more information, email volunteer@northwestharvest.org or call 206.923.7453, unless otherwise noted. more
  Volunteer FAQs
  Frequently Asked Questions About Volunteering more
  Sign Up/Apply to Volunteer
  All volunteer shifts must be scheduled in advance through the volunteer office. No drop-ins, please. Submit a group or an individual online application to receive a reply from a staff member and schedule a shift.  more
  Volunteer Login
  Welcome to the Northwest Harvest Volunteer Information Center! This online feature gives volunteers an easy way to keep in touch with the volunteer program. more
  Hours Matching
  Many employers match the hours their employees give in volunteer service with dollars. Does yours? more
  Inclement Weather Policy
  During inclement weather, we opt for safety first. Regular hours may be changed, or if weather is extreme, we may be closed. Here’s how to know. more


With diverse portfolio of solutions, we work diligently with our partners to provide an integrated & hassle free services.
We partner with various industry players from AVSI, IT & Systems, Architects & Consultants, Project Management Companies & others.
If you find any matching synergies with Xtreme Media® please contact us through below form so we can get one step further together.

  Partner With Us





https://download.mantratecapp.com/Forms/DownLoadFiles

Windows -
https://download.mantratecapp.com/StaticDownload/Mantra_RD_Service_Manual_Windows.pdf

MFS100 Driver
https://download.mantratecapp.com/StaticDownload/MFS100Driver.exe
RD Service
https://download.mantratecapp.com/StaticDownload/MantraRDService.exe

Restart PC


Android -
https://download.mantratecapp.com/StaticDownload/Mantra_RD_Service_Manual_Android.pdf

1. Mantra RD Service
https://play.google.com/store/apps/details?id=com.mantra.rdservice
2. Mantra Management Client
https://play.google.com/store/apps/details?id=com.mantra.clientmanagement


If your device is not listed at Mantra Management Server than user will be notified with below message
so in this case you need to contact with our Servico Team at http://servico.mantratecapp.com Or
+91-79-49068000
http://servico.mantratecapp.com/


54SAXXC 1117885


  -->
 */